<div class="mat-typography" style="background-color: #303030; display: flex; flex-direction: column;" cdkDropListGroup>
  
  <mat-toolbar style="gap: 16px;">

    <div style="flex: 1">Schedule</div>

    <button [disabled]="dateControl.value" mat-button (click)="save()">Save</button>

    <mat-form-field subscriptSizing="dynamic">
      <mat-label>Open Grid</mat-label>
      <mat-select [formControl]="gridControl">
        <mat-option *ngFor="let grid of grids" [value]="grid.id">
          {{grid.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button mat-button (click)="blankGrid()">Blank</button>

    <div>{{ondemandAPI.start_date}} - {{ondemandAPI.end_date}}</div>

    <mat-form-field subscriptSizing="dynamic">
      <mat-label>Ondemand Week</mat-label>
      <input matInput [matDatepickerFilter]="dateFilter" [matDatepicker]="picker" [formControl]="dateControl">
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <button mat-icon-button (click)="settings = !settings">
      <mat-icon>
        settings
      </mat-icon>
    </button>

  </mat-toolbar>
  
  <div *ngIf="settings" style="padding: 16px;">
  
    <div layout="row" layout-padding layout-wrap="">
      <span (click)="tagFilter(keyword, $event)" [class.selected]="keyword.selected" class="genre-tag" *ngFor="let keyword of keywords">{{keyword.tag}}
        <span class="badge">{{keyword.count}}</span>
      </span>
    </div>

  </div>

  <div style="display: flex; flex-direction: row;  height: calc(100vh - 128px);" cdkScrollable >
    <div style="flex: 1; overflow-y: scroll; top: 0;" cdkScrollable>
      <div style="flex: 1; margin-top: 24px; margin-left: 48px; align-items: start" class="week">
      <div class="time-col" style="margin-left: -48px; width: 48px;">
        <div style="margin-top: -24px; height: 24px; font-size: 10px; line-height: 24px;"></div>
        <div class="minute" drop-channel="program" [style.height]="intervalSize + 'px'" *ngFor="let minute of minutes; index as $index">
          {{minute}}
        </div>
      </div>
      <div class="day-col" *ngFor="let day of days" >
        <div style="margin-top: -24px; height: 24px; font-size: 10px; line-height: 24px;">{{ getDay(day) }}</div>
        <div cdkDropList (cdkDropListEntered)="debug($event, day, minute)" (cdkDropListDropped)="onDrop($event, discardList, day, minute, $index)" [cdkDropListData]="{ day: day,  minute: minute }" class="minute" [style.height]="intervalSize + 'px'" *ngFor="let minute of minutes; index as $index">
          {{minute}}
        </div>
      </div>
      <div class="week-overlay" #weekOverlay>
        <div class="day-overlay" *ngFor="let day of days; index as key" [cdkDropListEnterPredicate]="nope" cdkDropList [cdkDropListData]="{ day: day }" > 
          <div class="program" [class.through]="dragging"
            *ngFor="let program of filterPrograms(programs[key]); index as $index "
            [style.top]="program.index * intervalSize + 'px'" 
            [style.minHeight]="intervalSize + 'px'" 
            [style.height]="(program.data && program.data.duration ? program.data.duration : 1) * intervalSize + 'px'" 
            cdkDrag 
            [cdkDragData]="{ program: program.data, day: day, index: $index}" 
            (cdkDragStarted)="dragStart($event)"
            (cdkDragEnded)="dragEnd($event)"
          >
            <div class="program-inner" [class]="program.data.studio === 1 ? 'studio-one' : program.data.studio === 2 ? 'studio-two' : 'studio-three' ">
              <div class="program-header" (mousedown)="mouseDown($event, program, $index, key, 'top')"></div>
              <div cdkDragHandle>
                <div class="program-text" (click)="editProgram(program, day, $index, programs[key])">{{program.data.text}} </div>
                <div class="program-media" *ngIf="program.data.media">
                  <!-- <a target="_blank"  [href]="program.data.media">
                    <mat-icon>play_arrow</mat-icon>
                  </a> -->

                  <a (click)="ondemandPlay(program.data)">
                    <!-- <a target="_blank"  [href]="program.data.media"> -->
                    <mat-icon>play_arrow</mat-icon>
                  </a>

                  <a (click)="ondemandDownload(program.data)">
                    <!-- <a target="_blank"  [href]="program.data.media"> -->
                    <mat-icon>download</mat-icon>
                  </a>
                </div>
                <div class="program-studio" (click)="studioClick(program, day, $index, programs[key])">   
                  {{program.data.studio ? program.data.studio: 0 }} 
                </div>
              </div>
              <div class="program-footer" 
              (mousedown)="mouseDown($event, program, $index, key, 'bottom')"></div>
            </div>
            <div class="program program-preview" [style.width]="previewWidth + 'px'" [style.height]="(program.data && program.data.duration ? program.data.duration : 1) * intervalSize + 'px'" *cdkDragPreview>
              <div class="preview-inner" [class]="program.data.studio === 1 ? 'studio-one' : program.data.studio === 2 ? 'studio-two' : 'studio-three'" >
                {{program.data.text}}
              </div>
            </div>
            <div class="placeholder" *cdkDragPlaceholder></div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <div style="display: flex; flex-direction: column; max-width: 320px; height: calc(100vh - 128px); overflow-y: scroll; ">

      <div class="filters" [formGroup]="filtersForm">
        <mat-form-field class="full-width">
          <mat-label>Search Programs</mat-label>
          <input matInput formControlName="name">
          <button matTooltip="Add Program" (click)="addProgram()" matSuffix mat-icon-button><mat-icon>add</mat-icon></button>
        </mat-form-field>
        <mat-checkbox formControlName="active">Active</mat-checkbox>
        <mat-checkbox formControlName="amrap">Amrap</mat-checkbox>
        <mat-checkbox formControlName="archived">Archived</mat-checkbox>

      </div>

      <div class="tag-container" style="" cdkDropList (cdkDropListDropped)="onDiscardDrop($event)" (cdkDropListEntered)="debug($event, null, null)" [cdkDropListSortingDisabled]="true">
        <div class="tag" *ngFor="let program of filteredProgramsAZ; index as $index" ng-class="program.active ? 'exists': ''" cdkDrag [cdkDragData]="{ program: program }" (cdkDragDropped)="dropSuccessHandler($event, $index, programsAZ)" >{{program.text}}
          <div class="program program-preview" [style.width]="previewWidth + 'px'" [style.height]="(program && program.duration ? program.duration : 1) * intervalSize + 'px'" *cdkDragPreview>
            <div class="preview-inner" [class]="program.studio === 1 ? 'studio-one' : program.studio === 2 ? 'studio-two' : 'studio-three'" >
              {{program.text}}
            </div>
          </div>
          <div class="placeholder" *cdkDragPlaceholder></div>
        </div>
        
      </div>
    </div>
  </div>
</div>