const template = `
  <table width="100%" bgcolor="#201f1b" style="box-sizing: border-box; padding: 0; margin: 0; width: 100%; background: #201f1b url(https://zedletter.4zzz.org.au/skinning-min.png) repeat scroll 0 0; background-color: #201f1b; background-image: url(http://zedletter.4zzz.org.au/skinning-min.png); background-repeat: repeat; background-position: left top; background-attachment: scroll; background-size: auto;" background="http://zedletter.4zzz.org.au/skinning-min.png">
   <tbody style="box-sizing: border-box;">
      <tr bgcolor="#skinning-min.png" style="box-sizing: border-box; background-image: url(none); background-repeat: repeat; background-position: left top; background-attachment: scroll; background-size: auto; background-color: #skinning-min.png;" background="none">
         <td style="box-sizing: border-box; background-image: url(http://zedletter.4zzz.org.au/skinning-min.png); background-repeat: repeat; background-position: left top; background-attachment: scroll; background-size: auto;" background="http://zedletter.4zzz.org.au/skinning-min.png">
            <table border="0" width="713" cellspacing="0" cellpadding="10" style="box-sizing: border-box; padding: 10px; margin: 0 auto; background: #fff url(https://zedletter.4zzz.org.au/none) repeat scroll 0 0; border: 0 solid #eee; width: 713px; min-height: 500px;">
               <tbody style="box-sizing: border-box;">
                  <tr style="box-sizing: border-box;">
                     <td style="box-sizing: border-box; paddinhg-bottom: 10px; text-align: center;" align="center">
                        <img src="https://zedletter.4zzz.org.au/nfkaheader800-min.png" alt="" width="800px" style="box-sizing: border-box;">
                     </td>
                  </tr>
                  <tr style="box-sizing: border-box; min-height: 400px;">
                     <td style="box-sizing: border-box; border-top: 7px solid #000;">
                        <table style="box-sizing: border-box; height: 150px; margin: 0 auto 10px auto; padding: 5px 5px 5px 5px; width: 100%;" width="100%" height="150">
                           <tbody style="box-sizing: border-box;">
                              <tr style="box-sizing: border-box;">
                                 <td style="box-sizing: border-box; padding: 0; margin: 0; vertical-align: top;" valign="top">
                                    <div style="box-sizing: border-box; padding: 10px;">
                                       <p style="box-sizing: border-box; text-align: center;">
                                          <em style="box-sizing: border-box;">
                                          <span style="box-sizing: border-box; font-size: 11px;">Welcome to 4ZZZ’s weekly&nbsp;newsletter&nbsp;for announcers and volunteers!
                                          <br style="box-sizing: border-box;">
                                          <br style="box-sizing: border-box;">It can be difficult to keep on top of all of the missives that 4ZZZ HQ disseminates week to week, so in the interest of keeping your inboxes under control and our messaging clear, we’ll be doing our darndest to compile all of our comms into one weekly mailout. ​​​​​​​
                                          <br style="box-sizing: border-box;">
                                          <br style="box-sizing: border-box;">​​​​​​​Obviously there will be exceptions from time to time, but consider The&nbsp;Newsletter&nbsp;Formerly&nbsp;Known As your Monday afternoon treat (named so because we expect you to be able to&nbsp;
                                          <a data-cke-saved-href="https://www.youtube.com/watch?v=jJ1l4UJW04Y" href="https://www.youtube.com/watch?v=jJ1l4UJW04Y" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.youtube.com/watch?v%3DjJ1l4UJW04Y&amp;source=gmail&amp;ust=1601345022175000&amp;usg=AFQjCNGux9lWsvAiG4wG7oGnVMLee-WAkQ" style="box-sizing: border-box;">regurgitate</a>&nbsp;the information herein).
                                          </span>
                                          </em>
                                       </p>
                                    </div>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                        <table style="box-sizing: border-box; height: 150px; margin: 0 auto 10px auto; padding: 5px 5px 5px 5px; width: 100%;" width="100%" height="150">
                           <tbody style="box-sizing: border-box;">
                              <tr style="box-sizing: border-box;">
                                 <td style="box-sizing: border-box; padding: 0; margin: 0; vertical-align: top;" valign="top">
                                    <div style="box-sizing: border-box; padding: 10px;">
                                       <p style="box-sizing: border-box;">Insert your text here</p>
                                    </div>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                        <table cellspacing="0" style="box-sizing: border-box; width: 100%; min-height: 149px;" width="100%">
                           <tbody style="box-sizing: border-box;">
                              <tr style="box-sizing: border-box; background-color: #ffffff;" bgcolor="#ffffff">
                                 <td style="box-sizing: border-box; padding: 8px 0 8px 8px;">
                                    <a href="https://link" style="box-sizing: border-box;">
                                    <img src="https://placehold.co/280x280/78c5d6/fff/" alt="placeholder" width="280" style="width:280px; height: auto; display: block; border: 0;">
                                    </a>
                                 </td>
                                 <td style="box-sizing: border-box; vertical-align: top; font-family: Verdana, Geneva, sans-serif; font-size: 11px; padding: 8px; text-align: left;" valign="top" align="left">
                                    <h3 style="box-sizing: border-box;">A heading</h3>
                                    <p style="box-sizing: border-box;">
                                       Some demo text, with a
                                       <a href="https://link" style="box-sizing: border-box;">link</a> that goes somewhere. I'm going to pad it out a bit, because we'd want to do that anyway.. it's pretty great. Right?
                                       <br style="box-sizing: border-box;">
                                       <br style="box-sizing: border-box;">And another section with some
                                       <strong style="box-sizing: border-box;">bold text</strong>.
                                    </p>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                        <table cellspacing="0" style="box-sizing: border-box; width: 100%; min-height: 160px;" width="100%">
                           <tbody style="box-sizing: border-box;">
                              <tr style="box-sizing: border-box; background-color: #ffffff;" bgcolor="#ffffff">
                                 <td style="box-sizing: border-box; vertical-align: top; font-family: Verdana, Geneva, sans-serif; font-size: 11px; padding: 8px;" valign="top">
                                    <h3 style="box-sizing: border-box;">A heading</h3>
                                    <p style="box-sizing: border-box;">
                                       Some demo text, with a
                                       <a href="https://link" style="box-sizing: border-box;">link</a> that goes somewhere. I'm going to pad it out a bit, because we'd want to do that anyway.. it's pretty great. Right?
                                       <br style="box-sizing: border-box;">
                                       <br style="box-sizing: border-box;">And another section with some
                                       <strong style="box-sizing: border-box;">bold text</strong>.
                                    </p>
                                 </td>
                                 <td style="box-sizing: border-box; padding: 8px 8px 8px 0;">
                                    <a href="https://link" style="box-sizing: border-box;">
                                    <img src="https://placehold.co/280x280/78c5d6/fff/" alt="placeholder" width="280" style="width:280px; height: auto; display: block; border: 0;">
                                    </a>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                        <table cellspacing="0" style="box-sizing: border-box; width: 100%; min-height: 149px;" width="100%">
                           <tbody style="box-sizing: border-box;">
                              <tr style="box-sizing: border-box; background-color: #ffffff;" bgcolor="#ffffff">
                                 <td style="box-sizing: border-box; padding: 8px 0 8px 8px;">
                                    <a href="https://link" style="box-sizing: border-box;">
                                    <img src="https://placehold.co/280x280/78c5d6/fff/" alt="placeholder" width="280" style="width:280px; height: auto; display: block; border: 0;">
                                    </a>
                                 </td>
                                 <td style="box-sizing: border-box; vertical-align: top; font-family: Verdana, Geneva, sans-serif; font-size: 11px; padding: 8px; text-align: left;" valign="top" align="left">
                                    <h3 style="box-sizing: border-box;">A heading</h3>
                                    <p style="box-sizing: border-box;">
                                       Some demo text, with a
                                       <a href="https://link" style="box-sizing: border-box;">link</a> that goes somewhere. I'm going to pad it out a bit, because we'd want to do that anyway.. it's pretty great. Right?
                                       <br style="box-sizing: border-box;">
                                       <br style="box-sizing: border-box;">And another section with some
                                       <strong style="box-sizing: border-box;">bold text</strong>.
                                    </p>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                        <table cellspacing="0" style="box-sizing: border-box; width: 100%; min-height: 149px;" width="100%">
                           <tbody style="box-sizing: border-box;">
                              <tr style="box-sizing: border-box; background-color: #ffffff;" bgcolor="#ffffff">
                                 <td style="box-sizing: border-box; padding: 8px 0 8px 8px;">
                                    <a href="https://link" style="box-sizing: border-box;">
                                    <img src="https://placehold.co/280x280/78c5d6/fff/" alt="placeholder" width="280" style="width:280px; height: auto; display: block; border: 0;">
                                    </a>
                                 </td>
                                 <td style="box-sizing: border-box; vertical-align: top; font-family: Verdana, Geneva, sans-serif; font-size: 11px; padding: 8px; text-align: left;" valign="top" align="left">
                                    <h3 style="box-sizing: border-box;">A heading</h3>
                                    <p style="box-sizing: border-box;">
                                       Some demo text, with a
                                       <a href="https://link" style="box-sizing: border-box;">link</a> that goes somewhere. I'm going to pad it out a bit, because we'd want to do that anyway.. it's pretty great. Right?
                                       <br style="box-sizing: border-box;">
                                       <br style="box-sizing: border-box;">And another section with some
                                       <strong style="box-sizing: border-box;">bold text</strong>.
                                    </p>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                        <table cellspacing="0" style="box-sizing: border-box; width: 100%; min-height: 149px;" width="100%">
                           <tbody style="box-sizing: border-box;">
                              <tr style="box-sizing: border-box; background-color: #ffffff;" bgcolor="#ffffff">
                                 <td style="box-sizing: border-box; padding: 8px 0 8px 8px;">
                                    <a href="https://link" style="box-sizing: border-box;">
                                    <img src="https://placehold.co/280x280/78c5d6/fff/" alt="placeholder" width="280" style="width:280px; height: auto; display: block; border: 0;">
                                    </a>
                                 </td>
                                 <td style="box-sizing: border-box; vertical-align: top; font-family: Verdana, Geneva, sans-serif; font-size: 11px; padding: 8px; text-align: left;" valign="top" align="left">
                                    <h3 style="box-sizing: border-box;">A heading</h3>
                                    <p style="box-sizing: border-box;">
                                       Some demo text, with a
                                       <a href="https://link" style="box-sizing: border-box;">link</a> that goes somewhere. I'm going to pad it out a bit, because we'd want to do that anyway.. it's pretty great. Right?
                                       <br style="box-sizing: border-box;">
                                       <br style="box-sizing: border-box;">And another section with some
                                       <strong style="box-sizing: border-box;">bold text</strong>.
                                    </p>
                                 </td>
                              </tr>
                           </tbody>
                        </table>

                        <table style="box-sizing: border-box; height: 150px; margin: 0 auto 10px auto; padding: 5px 5px 5px 5px; width: 100%;" width="100%" height="150">
                           <tbody style="box-sizing: border-box;">
                              <tr style="box-sizing: border-box;">
                                 <td style="box-sizing: border-box; padding: 0; margin: 0; vertical-align: top;" valign="top">
                                    <div style="box-sizing: border-box; padding: 10px;">
                                       <p style="box-sizing: border-box; text-align: center;">
                                          <i style="box-sizing: border-box;">Thanks for playing along! 
                                          <br />
                                          If you have any big interviews, noteworthy subs shoutouts or particularly heroic volunteering work that deserves a shout out, let me know by emailing <a href="mailto:manager@4zzz.org.au">manager@4zzz.org.au</a> by 2pm Mondays. 
                                          <br />Got any burning questions about the studio, station policy or other FAQs? Check the <a data-cke-saved-href="https://wiki.4zzz.org.au/index.php/Main_Page" href="https://wiki.4zzz.org.au/index.php/Main_Page" style="box-sizing: border-box;">4ZZZ Wiki</a> out, 
                                          <br />if accessing from outside of the station use the following loging credentials: zeduser / 4zzzrules. 
                                          <br /> <br />
                                          Have a great week connecting and broadcasting the voices of our communities!
                                          </i>
                                       </p>
                                    </div>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </td>
                  </tr>
                  <tr style="box-sizing: border-box;">
                     <td colspan="3" align="center" height="30" style="box-sizing: border-box; text-align: center; border-top: 7px solid #000; background: #fff;">
              <a href="https://facebook.com/4zzzradio" target="_blank" style="text-decoration: none;">
                <img style="width: 42px; height: 42px; padding: 2px;" src="https://zedletter.4zzz.org.au/facebook42-min.png" alt="facebook.com/4zzzradio" width="42" height="42" />
              </a>
              <a href="https://bsky.app/profile/4zzzradio.bsky.social" target="_blank" style="text-decoration: none;">
                <img style="width: 42px; height: 42px; padding: 2px;" src="https://zedletter.4zzz.org.au/bluesky42-min.png" alt="4zzzradio.bsky.social" width="42" height="42" />
              </a>
              <a href="http://instagram.com/4zzzradio" target="_blank" style="text-decoration: none;">
                <img style="width: 42px; height: 42px; padding: 2px;" src="https://zedletter.4zzz.org.au/instagram42-min.png" alt="instagram.com/4zzzradio" width="42" height="42" />
              </a>
              <a href="https://www.tiktok.com/@4zzzradio" target="_blank" style="text-decoration: none;">
                <img style="width: 42px; height: 42px; padding: 2px;" src="https://zedletter.4zzz.org.au/tiktok42-min.png" alt="tiktok.com/@4zzzradio" width="42" height="42" />
              </a>
                                <br />
                                <br />
                Thank you for supporting 4ZZZ!  <br />
                <a href="https://support.4zzz.org.au/subscriptions">Renew your subscription</a> | <a href="https://support.4zzz.org.au/merch">Show off your love with merch</a>
                        <br style="box-sizing: border-box;">
                     </td>
                  </tr>
                  <tr style="box-sizing: border-box;"></tr>
               </tbody>
            </table>
            <p style="box-sizing: border-box;"></p>
         </td>
      </tr>
   </tbody>
</table>
<p style="box-sizing: border-box; display: none;"></p>
<p style="box-sizing: border-box;"></p>
`;

export default template;