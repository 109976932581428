const template = `<table 
  width="100%" 
  border="0" 
  cellspacing="0" 
  cellpadding="0" 
  style="margin:0; padding:0; width:100%; background:#201f1b url('https://zedletter.4zzz.org.au/skinning-min.png') repeat;">
  <tr>
    <!-- Center everything in this TD -->
    <td align="center" valign="top" style="padding:0; margin:0; background-color:#201f1b;">
      
      <!-- Inner container with a max-width -->
      <table 
        border="0" 
        cellspacing="0" 
        cellpadding="10"
        style="
          width:100%; 
          max-width:600px; 
          margin:0 auto; 
          background:#ffffff; 
          border:0; 
        ">
        
        <!-- Header / Hero Image Row -->
        <tr>
          <td align="center" style="padding:10px;">
            <!-- Make image fluid -->
            <img 
              src="https://zedletter.4zzz.org.au/ZedletterHeader600.png" 
              alt="4ZZZ Zedletter weekly updates on the world of zed!" 
              style="width:100%; max-width:600px; height:auto; display:block; border:0;"
            />
          </td>
        </tr>
        
        <!-- Main Content Row -->
        <tr style="min-height:400px;">
          <td style="border-top: 7px solid #000;">
            <!-- Your main content goes here -->
          </td>
        </tr>

        <!-- Social / Footer Row -->
        <tr>
          <td 
            align="center" 
            height="30" 
            style="
              text-align:center; 
              border-top:7px solid #000; 
              background:#fff;
              font-family: Verdana, Geneva, sans-serif;
              font-size: 10px;
              line-height: 1.4;
            "
          >
            <!-- Social icons in a row -->
            <a 
              href="https://facebook.com/4zzzradio" 
              target="_blank"
              style="text-decoration:none;"
            >
              <img 
                src="https://zedletter.4zzz.org.au/facebook42-min.png" 
                alt="facebook.com/4zzzradio"
                style="width:42px; height:42px; padding:2px;"
              />
            </a>
            <a 
              href="https://bsky.app/profile/4zzzradio.bsky.social" 
              target="_blank"
              style="text-decoration:none;"
            >
              <img 
                src="https://zedletter.4zzz.org.au/bluesky42-min.png" 
                alt="4zzzradio.bsky.social"
                style="width:42px; height:42px; padding:2px;"
              />
            </a>
            <a 
              href="http://instagram.com/4zzzradio" 
              target="_blank"
              style="text-decoration:none;"
            >
              <img 
                src="https://zedletter.4zzz.org.au/instagram42-min.png" 
                alt="instagram.com/4zzzradio"
                style="width:42px; height:42px; padding:2px;"
              />
            </a>
            <a 
              href="https://www.tiktok.com/@4zzzradio" 
              target="_blank"
              style="text-decoration:none;"
            >
              <img 
                src="https://zedletter.4zzz.org.au/tiktok42-min.png" 
                alt="tiktok.com/@4zzzradio"
                style="width:42px; height:42px; padding:2px;"
              />
            </a>
            <br /><br />
            
            <strong style="font-weight:400;">
              That's all for this week, be sure to check your inbox next week for the next instalment of Zedletter!
              <br />
              For more information hit 
              <a href="http://4zzz.org.au" style="text-decoration:none; color:#000;">
                4zzz.org.au
              </a>
              <br /><br />
              <a href="https://4zzz.org.au/support" style="text-decoration:none; color:#000;">Support 4ZZZ</a>:
              <a href="https://support.4zzz.org.au/subscriptions" style="text-decoration:none; color:#000;">subscribe</a> | 
              <a href="https://4zzz.org.au/participate" style="text-decoration:none; color:#000;">participate</a> | 
              <a href="https://support.4zzz.org.au/donations" style="text-decoration:none; color:#000;">donate</a> | 
              <a href="https://support.4zzz.org.au/merch" style="text-decoration:none; color:#000;">merch</a>
              <br /><br />
              [[UNSUBSCRIBE]]
              <br /><br />
              <em>
                Respectfully acknowledging the traditional owners and custodians of the country on which we live 
                and that their sovereignty over this land was never ceded.
              </em>
                <a href="http://www.4zzzfm.org.au" data-highlightable="1">
                  <br data-highlightable="1" />
                </a>
            </strong>
          </td>
        </tr>
        
      </table>
      <!-- End inner container -->
      
    </td>
  </tr>
</table>
`;
export default template;
