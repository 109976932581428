import { Component, Inject, Renderer2, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { CdkDragDrop, CdkDragMove } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { any } from '@uirouter/core';
import { ProgramDialogComponent } from '../program-dialog/program-dialog.component';
import { Moment } from 'moment';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { GridSaveDialogComponent } from '../grid-save-dialog/grid-save-dialog.component';
import { KeywordsService } from '../keywords.service';
import { environment } from 'src/environments/environment';
import { PlaylistService } from '../playlist.service';
 
export interface Keyword {
  slug: string;
  tag: string;
  id: number;
  count: number;
  selected?: boolean | null;
}

@Component({
  selector: 'app-program-scheduler',
  templateUrl: './program-scheduler.component.html',
  styleUrls: ['./program-scheduler.component.scss']
})
export class ProgramSchedulerComponent {

  @ViewChild('weekOverlay') private weekOverlay;

  constructor(
    private renderer: Renderer2,
    private http: HttpClient,
    private handler: HttpBackend,
		// @Inject('$rootScope') private $rootScope: any,
		private KeywordsService: KeywordsService,
    public dialog: MatDialog,
    private PlaylistService: PlaylistService,
  ){
    this.pureHttp = new HttpClient(handler);
    KeywordsService.query().subscribe( data => { this.allKeywords =  data });
    this.fetchAll(null, undefined, undefined);

    this.filtersForm.valueChanges.subscribe( changes => {
      this.filteredProgramsAZ = this.programsAZ.filter( i => 
        ( 
          this.filtersForm.controls['name'].value ? 
          i.text.toLowerCase().includes( this.filtersForm.controls['name'].value.toLowerCase() )  && i.active === this.filtersForm.controls['active'].value &&  i.amrap === this.filtersForm.controls['amrap'].value &&  i.archived === this.filtersForm.controls['archived'].value  : 
          true && i.active === this.filtersForm.controls['active'].value &&  i.amrap === this.filtersForm.controls['amrap'].value &&  i.archived === this.filtersForm.controls['archived'].value 
        ) || i.new === true 
        ).sort( (a, b) => b.new && b.new === true )
    })

    this.http.get<any>(environment.musicDBapiSrc +'/grids').subscribe( data => this.grids = data );

    this.gridControl.valueChanges.subscribe( value => {
      console.log(value);
      this.dateControl.patchValue(null, { emitEvent: false });
      this.fetchAll(value, undefined, undefined);
    })

    this.dateControl.valueChanges.subscribe( value => {
      console.log(value);

      const start = value.format('YYYY-MM-DD');
			const end = moment(value).add(6, 'days').format('YYYY-MM-DD');
			console.log(start, end);
			this.fetchAll(null, start, end);
    })

  }

  ngOnDestroy() {
    this.mouseUpDestroyer();
    this.mouseMoveDestroyer();
  }

  ngAfterViewInit() {
    console.log(this.weekOverlay)
  }

  mouseUpDestroyer: Function = () => {};
  mouseMoveDestroyer: Function = () => {};

  dragHandleData: any = {};

  pureHttp: HttpClient;

  keywords: Keyword[] = [];
  allKeywords: any[] = [];

  dragging = false;

  program: any;

  settings = false;

  days: number[] = Array.from({length: 7}, (e, i)=> i);
  minutes: string[] = Array.from({length: 24 * 4}, (e, i)=> moment("12:00AM", "HH:mmA").day(0).add(i * 15, 'minutes').format("hh:mma"))
  programs: any[] = [ [], [], [], [], [], [], [] ];

  intervalSize = 15;

  discardList: any[] = [];

  previewWidth = 200;

  grid: any[] = [];
  grids: any[] = [];
  ondemandAPI: any = {};
  gridPrograms: any[] = [];
  allPrograms: any[] = [];
  localPrograms: any[] = [];
  programsAZ: any[] = [];
  filteredProgramsAZ: any[] = [];

  filteredPrograms: any[] = [];

  gridControl = new UntypedFormControl(null);
  dateControl = new UntypedFormControl(null);
  
  filtersForm = new UntypedFormGroup({
    name: new UntypedFormControl(''),
    active: new UntypedFormControl(false),
    amrap: new UntypedFormControl(false),
    archived: new UntypedFormControl(true),
  })


  dateFilter = (d: Moment | null): boolean => {
    const day = (d || moment()).day();
    return day === 0;
  };

  blankGrid() {
    this.dateControl.patchValue(null, { emitEvent: false });
    this.gridControl.patchValue(null, { emitEvent: false });
    this.gridPrograms = [];
    this.programs = [ [], [], [], [], [], [], [] ];
  }

  ondemandPlay(data) {
    console.log({ date: data.air_date , year: data.air_date.split('-')[0], time: data.time , station: 'fm' });
    this.http.post<any>(environment.musicDBapiSrc + '/archive/dl', { 
      date: data.air_date , year: data.air_date.split('-')[0], time: data.time , station: 'fm'
    }).subscribe( response => { 
      console.log(response)
      this.PlaylistService.prepForBroadcast({action: 'addAndPlay', playlist: [ { ...data, token: response.token } ]})
    });
  }

  ondemandDownload(data) {
    console.log({ date: data.air_date , year: data.air_date.split('-')[0], time: data.time , station: 'fm' });
    this.http.post<any>(environment.musicDBapiSrc + '/archive/dl', { 
      date: data.air_date , year: data.air_date.split('-')[0], time: data.time , station: 'fm'
    }).subscribe( response => { 
      console.log(response)


      var url = environment.dbUrl + '/fela/' + response.token;
      // var fileUrl = URL.createObjectURL(file);
      // $sce.trustAsResourceUrl(fileUrl);
      var a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.download = 'archive.mp3';
      a.click();

    });
  }


  tagFilter(keyword, $event) {
    keyword.selected = ! keyword.selected;
    this.filteredPrograms = this.ondemandAPI.programsFromBlock.filter(i => i.keywords.findIndex(i => this.keywords.filter(i => i.selected).map(i => i.tag).indexOf(i.tag) >= 0 ) >= 0).map(i => i.slug)
    console.log(this.filteredPrograms);
  }

  filterPrograms(array) {
    return array.filter(element => {
      if(this.filteredPrograms.length === 0)
        return true;
      return this.filteredPrograms.indexOf(element.data.slug) >= 0;
    })
  }

  fetchAll(gridId, start, end) {
    const allPrograms = this.pureHttp.get<any>('https://airnet.org.au/rest/stations/4ZZZ/programs');
    const fmGrid = this.pureHttp.get<any>('https://airnet.org.au/rest/stations/4ZZZ/guides/fm');
    const grid = this.http.get<any>(environment.dbUrl + '/grid/');
    const ondemand = (start && end) ? this.http.get<any>(environment.musicDBapiSrc + '/episodes/week/'+start+'/'+end ) : this.http.get<any>( environment.musicDBapiSrc + '/grid' + ( gridId ? '/' + gridId  : '') );
    const dbPrograms = this.http.get<any>(environment.musicDBapiSrc + '/schedule-programs');

    this.gridPrograms = [];
    this.programs = [ [], [], [], [], [], [], [] ];

    forkJoin(allPrograms, grid, ondemand, fmGrid, dbPrograms).subscribe( data => {

      const [ allPrograms, grid, ondemand, fmGrid, dbPrograms ] = data;
      const dayStringToNumber = { Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5, Saturday: 6, Sunday: 0 }

      this.grid = grid;

      this.ondemandAPI = ondemand;

      if (!gridId) {
        this.gridControl.patchValue(this.ondemandAPI.id, { emitEvent: false });
      }

      let programsAll = this.ondemandAPI.programs;

      for (var day in this.ondemandAPI.gridByBlockWeek) {
        for (var time in this.ondemandAPI.gridByBlockWeek[day]) {

          if (this.ondemandAPI.gridByBlockWeek[day].hasOwnProperty(time)) {
            var program = this.ondemandAPI.gridByBlockWeek[day][time]
            this.gridPrograms.push(program);
            let timeSplit: string[] = time.split(':')
            var index = (parseInt(timeSplit[0]) * 4) + (parseInt(timeSplit[1]) / 15)
            this.programs[dayStringToNumber[day]].push({ 
              index: index, 
              data: {
                duration: program.duration / 3600 * 4,
                media: program.media, 
                air_date: program.air_date,
                time: program.test, 
                text: program.program.name, 
                slug: program.program.slug, 
                id: program.program.id, 
                studio: program.studio 
              } 
            })

          }
        }
      }
      console.log('got it!')

      this.allPrograms = allPrograms.map((i, n) => {
        let inGrid = this.gridPrograms.find( p => p.slug === i.slug );
        return { ...i, duration: 4, text: i.name, amrap: true, active: inGrid !== undefined }
      });

      let fmPrograms = fmGrid;

      let localPrograms = dbPrograms.map((i, n) => {
        let inGrid = this.gridPrograms.find( p => p.slug === i.slug );
        return { ...i, archived: true, media: i.media, duration: i.duration / 3600 * 4, text: i.name, broadcasters: i.presenters, amrap: false, active: inGrid !== undefined }
      });

      localPrograms = localPrograms.sort(( a, b ) => b.id - a.id ).filter((obj1, i, arr) => 
        arr.findIndex(obj2 => (obj2.name === obj1.name)) === i
      )

      this.localPrograms = localPrograms;

      let something = {}; 
      
      localPrograms.forEach( (i,n) => { 
        something = { ...something, [i.slug]: something[i.slug] ? [ ...something[i.slug], i ] : [i] } 
      }); 

      console.log(something);
      let limit: any[] = []; 
      Object.keys(something).forEach( i => { 
        limit = [...limit, something[i].sort( (a,b) => a.id < b.id )[0] ] 
      });

      this.programsAZ = [ ...this.allPrograms, ...this.localPrograms ];
      this.filteredProgramsAZ = this.programsAZ.sort( (a, b) => a.name.localeCompare(b.name))
      console.log(this.programsAZ)

      // let slugs = $scope.ondemandAPI.programsFromBlock.map( i => i.slug );
      // $scope.filteredPrograms = [ ...new Set (slugs) ];
      // $scope.filteredPrograms.sort((a,b) => a.localeCompare(b));


      // let keywords = []; 
      // $scope.ondemandAPI.programsFromBlock.forEach(i => { i.keywords.forEach( k => keywords = [ ...keywords, { tag: k.tag, selected: false }] )} ); 
      // console.log(keywords);
      // $scope.keywords = [ ...new Set (keywords) ];
      // $scope.keywords.sort((a,b) => a.localeCompare(b));

      this.ondemandAPI.programsFromBlock.forEach( item => {

        if( item.keywords.length === 0 ) {
          console.log('UNTAGGED')
          let i = this.keywords.findIndex( x => x.tag === 'UNTAGGED');
          if( i === -1){
            this.keywords.push ({tag: 'UNTAGGED', slug: 'untagged', count: 1, id: -1})
          }
          else {
            this.keywords[i].count++
          }
        }
        item.keywords.forEach( keyword => {
          let i = this.keywords.findIndex( x => x.tag === keyword.tag );
          if( i === -1){
            this.keywords.push ({ tag: keyword.tag, slug: keyword.slug, id: keyword.id, count: 1 })
          }
          else {
            this.keywords[i].count++
          }
        })
      });
      this.keywords.sort((a, b) => (a.count < b.count) ? 1 : -1 )
      console.log(this.keywords)

      this.previewWidth = this.weekOverlay.nativeElement.firstChild.clientWidth;
    })
  }


  save() {
    const dialogRef = this.dialog.open(GridSaveDialogComponent, {
      data: {
        grid: this.ondemandAPI
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result !== 'abort') {
        console.log(result);
        // this.program = result;

        if(result.action === 'save') {
          this.http.post(environment.musicDBapiSrc + '/grid-save', { 
            gridId: this.ondemandAPI.id, 
            gridName: result.data.name, 
            gridStart: result.data.start_date, 
            gridEnd: result.data.end_date, 
            gridDraft: result.data.draft,  
            gridStation: result.data.station,  
            programs: this.programs 
          }).subscribe( response => { console.log(response); } );
        }
        if(result.action === 'rename' || result.action === 'saveAs') {
          this.http.post(environment.musicDBapiSrc + '/grid-save', { 
            gridId: result.action === 'rename' ? this.ondemandAPI.id : null, 
            gridName: result.data.name,
            gridStart: typeof result.data.start_date === 'object' ? result.data.start_date.format('YYYY-MM-DD') :  result.data.start_date, 
            gridEnd: typeof result.data.end_date === 'object' ? result.data.end_date.format('YYYY-MM-DD') : result.data.end_date, 
            gridStation: result.data.station,  
            gridDraft: result.data.draft,  
            programs: this.programs 
          }).subscribe( response => { console.log(response); } );
        }
      }
    });
  }

  addProgram() {
    // console.log($scope.programs[day][$index]);
    this.program = { keywords: [], backup: [], announcers: []}

    const dialogRef = this.dialog.open(ProgramDialogComponent, {
      data: {
        program: this.program 
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result !== 'abort') {
        console.log(result);
        this.programsAZ.push({
          id: result.id, 
          new: true,
          text: result.name, 
          name: result.name, 
          slug: result.slug, 
          presenters: result.presenters,
          duration: Number(result.duration),
          studio: Number(result.studio),
          rdscat: result.rdscat,
          keywords: result.keywords,
          backup: result.backup,
          announcers: result.announcers,
        });
        
        this.filtersForm.updateValueAndValidity();
        // this.program = result;
      }
    });
  }

  editProgram(program, day, $index, programKey) {
    this.program = this.localPrograms.find(i => i.id === program.data.id)
    console.log(program, day, $index);
    console.log(this.program);
    // console.log($scope.programs[day][$index]);

    const dialogRef = this.dialog.open(ProgramDialogComponent, {
      data: {
        program: this.program ? this.program : program.data
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result !== 'abort') {
        console.log(result);
        // this.program = result;
        result.duration = Number(result.duration)
        result.studio = Number(result.studio)
        let program = this.programs[day][$index];
        program.data = {...program.data, ...result }
      }
    });
  }

  getDay = (day: number) => {
    return moment().day(day).format('dddd');
  }


  dropFailureHandler = ($event,index,array, $data) => {
    console.log($event)
    array.push($data);
  }

  dropValidate = (target, source) => {
    console.log(target)
    console.log(source)
  }

  computeDragRenderPos = (pos, dragRef) => {
    return {x: pos.x, y: Math.floor(pos.y / this.intervalSize + 0.5) * this.intervalSize}; // will render the element every 30 pixels horizontally
  }

  mouseDown($event, program, $index, day, position) {
    $event.preventDefault()
    let newProgram = JSON.parse(JSON.stringify(program));
    var duration = this.programs[day][$index].data.duration;
    
    this.dragHandleData = { initX: $event.pageX, initY: $event.pageY, segments: 0, program: newProgram, index: $index, day: day, duration: duration, position: position }

    this.mouseMoveDestroyer = this.renderer.listen('document', 'mousemove', event => {
      event.preventDefault()

      var distanceX = event.pageX - this.dragHandleData.initX
			var distanceY = event.pageY - this.dragHandleData.initY
			if (this.dragHandleData.segments != Math.floor(distanceY / this.intervalSize + 0.5)) {
				var day = this.dragHandleData.day
				var index = this.dragHandleData.index
				this.dragHandleData.segments = Math.floor(distanceY / this.intervalSize + 0.5)
				if (this.dragHandleData.position === 'bottom') {
					this.programs[day][index].data.duration = this.dragHandleData.duration + this.dragHandleData.segments
				}
				if (this.dragHandleData.position === 'top') {
					this.programs[day][index].index =  this.dragHandleData.program.index  + this.dragHandleData.segments
					this.programs[day][index].data.duration = this.dragHandleData.duration - this.dragHandleData.segments

				}
      }
    });

    this.mouseUpDestroyer = this.renderer.listen('document', 'mouseup', e => {
      this.mouseMoveDestroyer()
      this.mouseUpDestroyer()
    });
  }

  onDrop = ($event: CdkDragDrop<any>, array, day, minute, $index) =>{
    console.log($event)
    
    let $data = $event.item.data;

    if($event.previousContainer.data && $event.container.data) {
      let prevDay = ($event.previousContainer.data.day)
      let showIndex = this.programs[prevDay].findIndex( i => i.data === $data.program);
      console.log(showIndex, $event.previousIndex);
      this.programs[prevDay].splice(showIndex, 1);
      this.programs[day].push({ index: $index, data: $data.program } );
      this.programs[day].sort((a,b) => a.index - b.index );
    }

    else { 
      this.programs[day].push({ index: $index, data: $data.program } );
    }
    //console.log($data)
    //console.log(array)
    //console.log(day)
    //console.log(minute)
    //console.log($index)
    if (day === 'unused' ) {
      this.discardList.push($data);
    }
    // this.programs[day].push({ data: $data, minute: minute, index: $index } );

    console.log(this.programs )
  };

  onDiscardDrop = ($event) => {
    let $data = $event.item.data;
    console.log($event)
    let prevDay = ($data.day)
    let showIndex = this.programs[prevDay].findIndex( i => i.data === $data.program);
    console.log(showIndex);
    this.programs[prevDay].splice(showIndex, 1);
  }


  dragStart = ($event) => {
    console.log($event)
    this.dragging = true;
  }

  dragEnd = ($event) => {
    console.log($event)
    this.dragging = false;
  }

  debug = ($event, day, minute) => {
    console.log(day, minute, $event)
  }

  dropSuccessHandler = ($event,index,array) => {
    console.log($event)
    //console.log(index)
    //console.log(array)
    array.splice(index,1);
  };

  nope = () => false;

  // dragEnd = ($event) => {
  //   console.log($event.distance, $event.dropPoint, $event, $event.source)

  //   let parent = $event.source.element.nativeElement.parentElement?.parentElement;
  //   let changedParentY = ( parent?.offsetHeight ? parent?.offsetHeight : 0 ) + $event.distance.y;

  //   console.log($event.source.data.program.data.duration)
  //   let segments = Math.floor(changedParentY / this.intervalSize + 0.5)
  //   console.log(segments)
  //   $event.source.data.program.data.duration = segments;

  // }


  dragMove($event: CdkDragMove<any>) {
    // console.log($event)
    console.log($event.distance)
    let parent = $event.source.element.nativeElement.parentElement?.parentElement;
    let changedParentY = ( parent?.offsetHeight ? parent?.offsetHeight : 0 ) + $event.distance.y;

    console.log($event.source.data.program.data.duration)
    let segments = Math.floor(changedParentY / this.intervalSize + 0.5)
    console.log(segments)

    // $event.source.data.program.data.duration = segments;
    // target.style.height = height + 'px';
    // this.ngZone.runOutsideAngular(() => {
    //   this.resize(dragHandle, this.resizeBoxElement);
    // });
  }

  

  studioClick(program, day, $index, programKey) {
    program.data.studio = (program.data.studio) % 3 + 1;
    console.log(program.data.studio);

    // console.log($scope.programs[day][$index]);
  }


}
