	<div id="playlist" style="position: absolute; top: 0; left: 0px; bottom: 0; overflow: scroll; width: 100%; font-size: 10pt;" cdkScrollable>
		<div id="playlist-scroll" cdkDropList [cdkDropListAutoScrollDisabled]="false" [cdkDropListData]="tracks"  (cdkDropListDropped)="drop($event)">
			<div *ngFor="let track of tracks; index as i" cdkDrag class="track">

				<div class="length">{{track.length | hms }}</div>
				<div class="artist">{{track.artist && track.artist.name ? track.artist.name : track.artist }}</div>
				<div class="title">{{track.title}}</div>
				<div *ngIf="stationPlaylist" class="category">{{track.category}}</div>
				<div class="file">{{track.file | backslash}}</div>
				<div class="delete playlist-delete">
					<button (click)="deleteTrack(track, i)" mat-icon-button> 
						<mat-icon aria-label="Delete">
							delete
						</mat-icon>
					</button>
				</div>
					<!-- <mat-icon style="font-size: 14px; text-align: center" data-as-sortable-item-handle class="material-icons playlist-drag" aria-label="Drag">
					drag_indicator
					</mat-icon> -->
			</div>

			<div style="flex: 1;" *ngIf="!tracks.length">No tracks yet</div>

			
		</div>
		<div style="background: #303030; position: sticky; bottom: 0; display: flex; align-items: center; min-height: 48px; border-top: solid 1px #434343;">
			<div class="length" >{{totalLength | hms}}</div>
			<div style="flex: 1"></div>
			<button class="tool-icon" (click)="downloadPlaylist()" matTooltip="Download Playlist" mat-icon-button aria-label="Previous Tag" >
				<mat-icon>save_alt</mat-icon>
			</button>
		</div>
	</div>
